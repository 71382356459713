// Home.js

import React, { useState } from 'react';
import {
    FaCamera,
    FaBrain,
    FaUserMd,
    FaUtensils,
    FaBullseye,
    FaLeaf,
    FaApple,
    FaGooglePlay
} from 'react-icons/fa';
import { Link } from 'react-router-dom';

// Import all feature images
import phoneImage from './assets/phone-mockup.png';
import MacrosOverview from './assets/MacrosOverview.png';
import Insights from './assets/Insights.png';
import Pal from './assets/Pal.png';
import Recipes from './assets/Recipes.png';
import MealAnalysisImage from './assets/MealAnalysis.png';

const Feature = ({ title, description, icon: Icon }) => (
    <div className="bg-white bg-opacity-20 rounded-lg p-6 shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
        <div className="flex items-center justify-center mb-4">
            <Icon className="w-12 h-12 text-white" />
        </div>
        <h3 className="text-2xl font-semibold text-white mb-2 text-center">{title}</h3>
        <p className="text-lg text-white text-center">{description}</p>
    </div>
);

const DownloadButton = ({ store, icon: Icon }) => (
    <a href="#" className="bg-white text-blue-800 font-bold py-3 px-6 rounded-full shadow-lg transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 flex items-center justify-center">
        <Icon className="mr-2" /> Download on {store}
    </a>
);

function Home() {
    // State for form inputs
    const [formData, setFormData] = useState({
        name: '',
        email: ''
    });

    const [status, setStatus] = useState('');

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatus('Submitting...');

        try {
            const response = await fetch('https://formspree.io/f/mdknqzqb', { // Replace with your Formspree form ID
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                setStatus('Thank you for signing up! We will contact you soon.');
                setFormData({ name: '', email: '' });
            } else {
                setStatus('Oops! Something went wrong. Please try again.');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            setStatus('Oops! Something went wrong. Please try again.');
        }
    };

    return (
        <div className="min-h-screen bg-blue-800 text-white">
            <header className="bg-blue-900 bg-opacity-90 backdrop-filter backdrop-blur-lg py-4 px-6 md:px-12 fixed w-full z-50">
                <div className="container mx-auto flex justify-between items-center">
                    <h1 className="text-3xl font-bold">FitPalAi</h1>
                    <nav className="flex items-center">
                        <a href="#app-features" className="text-white hover:text-gray-300 ml-6 transition duration-200">App Features</a>
                        <Link to="/privacy-policy" className="text-white hover:text-gray-300 ml-6 transition duration-200">Privacy Policy</Link>
                    </nav>
                </div>
            </header>

            <main className="pt-20">
                {/* Hero Section */}
                <section className="py-20 px-6 md:px-12">
                    <div className="container mx-auto flex flex-col md:flex-row items-center justify-between">
                        <div className="md:w-1/2 mb-12 md:mb-0">
                            <h2 className="text-xl font-semibold mb-2 text-accent">Meal Tracking Made Easy</h2>
                            <h1 className="text-5xl font-bold mb-6 leading-tight">Achieve Your Fitness Goals with FitPal</h1>
                            <div className="flex space-x-4">
                                <DownloadButton store="App Store" icon={FaApple} />
                                <DownloadButton store="Google Play" icon={FaGooglePlay} />
                            </div>
                        </div>
                        <div className="md:w-1/2 flex justify-center">
                            <img src={phoneImage} alt="FitPal App Interface" className="max-w-full h-auto rounded-3xl transform hover:scale-110 transition-transform duration-300" />
                        </div>
                    </div>
                </section>

                {/* Features Section */}
                <section id="features" className="py-20 px-6 md:px-12 bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg">
                    <div className="container mx-auto">
                        <h2 className="text-4xl font-bold text-center mb-12 text-white">Why Choose FitPal</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                            <Feature
                                title="AI-Powered Meal Analysis"
                                description="Analyze your meals through image recognition for nutritional insights."
                                icon={FaCamera}
                            />
                            <Feature
                                title="Personalized Nutritional Recommendations"
                                description="Receive tailored advice based on your unique dietary needs and goals."
                                icon={FaBrain}
                            />
                            <Feature
                                title="AI Nutritionist Chatbot (Pal)"
                                description="Interact with Pal, your personal AI nutritionist, for guidance and support."
                                icon={FaUserMd}
                            />
                            <Feature
                                title="Simplified Meal Logging"
                                description="Easily log your meals with automatic macro tracking to streamline your nutrition management."
                                icon={FaUtensils}
                            />
                            <Feature
                                title="Goal Setting & Progress Monitoring"
                                description="Set fitness goals and monitor your progress with visual feedback and analytics."
                                icon={FaBullseye}
                            />
                            <Feature
                                title="Customized Recipe Suggestions"
                                description="Get recipe ideas tailored to your preferences and nutritional objectives."
                                icon={FaLeaf}
                            />
                        </div>
                    </div>
                </section>

                {/* App Features Section */}
                <section id="app-features" className="py-20 px-6 md:px-12 bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg">
                    <div className="container mx-auto">
                        <h2 className="text-4xl font-bold text-center mb-12 text-white">Explore FitPal's Features</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                            {/* Feature 1 */}
                            <div className="flex flex-col items-center">
                                <img src={Insights} alt="Monitor Your History with AI" className="w-auto h-auto rounded-3xl mb-4 transform hover:scale-105 transition-transform duration-300" />
                                <h4 className="text-2xl font-semibold text-white mb-2 text-center">Monitor Your History with AI</h4>
                                <p className="text-lg text-white text-center max-w-md">Track and analyze your nutritional intake and fitness progress over time with intelligent AI insights.</p>
                            </div>

                            {/* Feature 2 */}
                            <div className="flex flex-col items-center">
                                <img src={MealAnalysisImage} alt="Smart Meal Analysis" className="w-auto h-auto rounded-3xl mb-4 transform hover:scale-105 transition-transform duration-300" />
                                <h4 className="text-2xl font-semibold text-white mb-2 text-center">Smart Meal Analysis</h4>
                                <p className="text-lg text-white text-center max-w-md">Take a photo of your meal and let our AI analyze its nutritional content instantly.</p>
                            </div>

                            {/* Feature 3 */}
                            <div className="flex flex-col items-center">
                                <img src={Pal} alt="AI Nutritionist Pal" className="w-auto h-auto rounded-3xl mb-4 transform hover:scale-105 transition-transform duration-300" />
                                <h4 className="text-2xl font-semibold text-white mb-2 text-center">AI Nutritionist Pal</h4>
                                <p className="text-lg text-white text-center max-w-md">Meet Pal, your personal AI nutritionist, ready to provide customized guidance and support.</p>
                            </div>

                            {/* Feature 4 */}
                            <div className="flex flex-col items-center">
                                <img src={Recipes} alt="Healthy Recipes" className="w-auto h-auto rounded-3xl mb-4 transform hover:scale-105 transition-transform duration-300" />
                                <h4 className="text-2xl font-semibold text-white mb-2 text-center">Healthy Recipes</h4>
                                <p className="text-lg text-white text-center max-w-md">Explore a wide range of nutritious recipes tailored to your dietary preferences and goals.</p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Help Us Test Section */}
                <section id="help-us-test" className="py-20 px-6 md:px-12 bg-blue-700 bg-opacity-80 backdrop-filter backdrop-blur-lg">
                    <div className="container mx-auto max-w-lg">
                        <h2 className="text-3xl font-bold text-center mb-8">Want to Help Us Test?</h2>
                        <p className="text-lg text-center text-white mb-6">Have an Apple device and want to test our app? Join our beta testing community!</p>
                        <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                placeholder="Your Name"
                                required
                                className="px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="Your Email"
                                required
                                className="px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                            <button
                                type="submit"
                                className="bg-white text-blue-800 font-semibold py-2 px-4 rounded-md shadow-md hover:bg-gray-200 transition duration-300"
                            >
                                Submit
                            </button>
                            {status && <p className="text-center text-white mt-4">{status}</p>}
                        </form>
                    </div>
                </section>
            </main>

            <footer className="bg-blue-900 bg-opacity-90 backdrop-filter backdrop-blur-lg py-8 px-6 md:px-12">
                <div className="container mx-auto">
                    <div className="flex flex-col md:flex-row justify-between items-center">
                        <div className="mb-4 md:mb-0">
                            <p className="text-white">&copy; 2024 FitPal. All rights reserved.</p>
                            <div className="mt-2">
                                <Link to="/privacy-policy" className="text-accent hover:text-gray-300 mr-4">Privacy Policy</Link>
                                <a href="#" className="text-accent hover:text-gray-300 mr-4">Terms of Service</a>
                                <a href="#" className="text-accent hover:text-gray-300">Contact Us</a>
                            </div>
                        </div>
                        <div className="flex space-x-4">
                            <DownloadButton store="App Store" icon={FaApple} />
                            <DownloadButton store="Google Play" icon={FaGooglePlay} />
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );

}

export default Home;
