// PrivacyPolicyPage.js

import React from 'react';
import { Link } from 'react-router-dom';
import privacyPolicyText from './PrivacyPolicy'; // Corrected import path

function PrivacyPolicyPage() {
    return (
        <div className="min-h-screen bg-gradient-to-br from-primary via-secondary to-accent text-white">
            <header className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg py-4 px-6 md:px-12">
                <div className="container mx-auto flex justify-between items-center">
                    <Link to="/" className="text-3xl font-bold bg-white bg-clip-text text-transparent">FitPal</Link>
                </div>
            </header>

            <main className="container mx-auto py-12 px-6 md:px-12">
                <h1 className="text-4xl font-bold mb-8">Privacy Policy</h1>
                <div className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-lg p-8">
                    <pre className="whitespace-pre-wrap font-sans text-sm">
                        {privacyPolicyText}
                    </pre>
                </div>
            </main>

            <footer className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg py-8 px-6 md:px-12">
                <div className="container mx-auto text-center">
                    <p>&copy; 2024 FitPal. All rights reserved.</p>
                    <Link to="/" className="text-accent hover:text-white mt-2 inline-block">Back to Home</Link>
                </div>
            </footer>
        </div>
    );
}

export default PrivacyPolicyPage;
