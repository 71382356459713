// App.js

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './Home'; // Main Home Page Component
import PrivacyPolicyPage from './PrivacyPolicyPage'; // Privacy Policy Page Component

function App() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            {/* You can add more routes here as your application grows */}
        </Routes>
    );
}

export default App;
